<template>
  <div class="contact" id="Contact"> 
    <Header/>
    <div class="other__narrow__banner">
      <img  :src="'https://www.elevator.com.vn/upload/' + contact.image" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>{{contact.title}}</h4>
        </div>
      </div>
    </div>
    <div class="other__ck__block contact__section">
      <div class="container">
        <div class="other__ck__title title__bar">
          <h2>{{itemOne.title}}</h2>
        </div>
        <div class="other__ck__img contact__block">
          <div class="other__ck__img__box fit__img">
            <img src="../assets/pic/contact__img1.png" alt="" />
          </div>
          <div class="contact__info__list" v-html="itemOne.content">
          </div>
        </div>
      </div>
    </div>
    <div class="other__ck__block">
      <div class="container">
        <div class="other__ck__title title__bar">
          <!--服務信箱-->
          <h2>Hộp thư dịch vụ</h2>
        </div>
        <div class="other__forms"> 
          <form class="needs-validation" novalidate>
            <div class="form-row">
              <div class="col-md-6 mb-3">
                <!--你的姓名-->
                <label for="validationCustom01">tên thư của bạn</label>
                <input
                  v-model="name"
                  type="text"
                  class="form-control"
                  id="validationCustom01"
                  placeholder="Vui lòng nhập tên của bạn"
                  value="name"
                  required
                />
                <!--請輸入姓名-->
                <div class="invalid-feedback">
                  Vui lòng nhập tên của bạn
                </div>
              </div>
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <!--服務公司-->
                <label for="validationCustom02">công ty dịch vụ</label>
                <input
                  v-model="company"
                  type="text"
                  class="without_validate_input"
                  id="validationCustom02"
                  placeholder="Hãy tham gia vào công ty"
                  value="company"
                />
                <!--請輸入公司-->
                <div class="invalid-feedback">
                  Hãy tham gia vào công ty
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <!--聯絡電話-->
                <label for="validationCustom01">Số liên lạc</label>
                <input
                  v-model="phone"
                  type="text"
                  class="without_validate_input"
                  id="validationCustom01"
                  placeholder="Vui lòng nhập số điện thoại"
                  value="phone"
                />
                <!--請輸入電話-->
                <div class="invalid-feedback">
                  Vui lòng nhập số điện thoại
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="validationCustom02">E-mail</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  id="validationCustom02"
                  placeholder="Vui lòng nhập E-mail"
                  value="email"
                  required
                />
                <!--請輸入email-->
                <div class="invalid-feedback">
                  Vui lòng nhập E-mail
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mb-3 without_validate_wrapper">
                <!--聯絡傳真-->
                <label for="validationCustom01">liên hệ fax</label>
                <input
                  v-model="fax"
                  type="text"
                  class="without_validate_input"
                  id="validationCustom01"
                  placeholder="Vui lòng nhập số fax"
                  value="fax"
                />
                <!--請輸入傳真-->
                <div class="invalid-feedback">
                  Vui lòng nhập số fax
                </div>
              </div>
            </div>
            <div class="mb-3">
              <!--訊息內容-->
              <label for="validationCustom01">Nội dung tin nhắn</label>
              <textarea
                v-model="content"
                class="form-control"
                id="validationTextarea"
                placeholder="Vui lòng nhập nội dung tin nhắn"
                required
              ></textarea>
              <!--請輸入訊息內容-->
              <div class="invalid-feedback">
                Vui lòng nhập nội dung tin nhắn
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input
                  v-model="checkbox"
                  class="form-check-input"
                  type="checkbox"
                  value="checkbox"
                  id="invalidCheck2"
                  required
                />
                <!--本人已閱讀並同意本公司-->
                <label class="form-check-label" for="invalidCheck2" style="color:black">
                  Tôi đã đọc và lập kế hoạch cho công ty
                </label>
                
                <span>
                  <a class="other__ck__text__green"
                  data-toggle="modal" 
                  data-target="#exampleModal"
                  >
                  <!--個人資料蒐集政策-->
                    Chính sách Thu thập Thông tin Cá nhân
                  </a>
                </span>
                
                <div class="invalid-feedback">
                  <!--尚未同意個人資料蒐集政策-->
                  Chưa đồng ý với Chính sách thu thập thông tin cá nhân
                </div>
              </div>
            </div>
            
            <div class="modal fade" id="exampleModal"
                  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <!--個人資料蒐集政策-->
                    <h5 class="modal-title" id="staticBackdropLabel">Chính sách Thu thập Thông tin Cá nhân</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                <div class="modal-body" v-html="itemTwo.content">
                </div>
              </div>
              </div>
            </div>
            <button :disabled ="disable" class="btn btn_green btn_submit" @click.prevent.stop="SubmitData" type="submit" >
              <!--個人資料蒐集政策-->
              Xác nhận để gửi
            </button>  
          </form>
        </div>
      </div>
    </div>
    <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" :loadRecaptchaScript="true" sitekey="6Ld_ohoeAAAAAHqE5M_OY3nX2uwAY6ki5LeYvSJv"
    size="invisible"/>
  </div>
  
</template>



<script>
import { apiContact } from "@/api/pages.js";
import { VueRecaptcha } from 'vue-recaptcha';
import { Toast } from "./../utils/helpers"
import formPostAPI from "./../api/formPost"
import Header from './../components/Header'
// console.log(formPostAPI.SubmitContactPost)
export default {
  name: "Contact",
  components: {
    Header,
    VueRecaptcha 
  },
  data(){
    return{
      item: {},
      contact: {},
      itemOne: {},
      itemTwo:{},
      name: '',
      company: '',
      phone: '',
      email: '',
      fax: '',
      content: '',
      checkbox: false,
      token: '',
      disable: false
    }
  },
  mounted() {
    this.getData()
    document.querySelector('body').scrollIntoView({behavior: "smooth"})
  },
  methods: {
    async postDataHandler () {
      const form = document.querySelector('.needs-validation')
      this.disable = true
      try {
        const data_JSON = {
          name: this.name,
          company: this.company,
          phone: this.phone,
          email: this.email,
          fax: this.fax,
          content: this.content,
          token: this.token
        }
        const response = await formPostAPI.SubmitContactPost({ data_JSON })
        console.log(response) 
        if(response.data.result === 'OK'){
        // if(response.data.reCAPTCHA.success){
          Toast.fire({
            icon: 'success',
            title: 'Dữ liệu đã được gửi！'
          })
          this.name = ''
          this.company = ''
          this.phone = ''
          this.email = ''
          this.fax = ''
          this.content = ''
          this.checkbox = false
          form.classList.remove('was-validated')
        }
      }
      catch {
        Toast.fire({
          icon: 'warning',
          title: 'Truyền dữ liệu không thành công, vui lòng đợi!'
        })
        console.error('error')
      }
      this.disable = false
    },
    onVerify: function (response) {
      this.token = response
      this.postDataHandler()
    },
    onExpired: function () {
      console.log('Expired')
    },
    errorMethod: function () {
      console.log('觸發 vue-recaptcha errorMethod')
    },
    resetRecaptcha() {
      console.log('進入resetRecaptcha')
      this.$refs.recaptcha.reset() // Direct call reset method
    },
    async SubmitData () {
      const form = document.querySelector('.needs-validation')
      form.classList.add('was-validated')
      if(!form.checkValidity()) return
      this.$refs.recaptcha.execute()
    },
    async getData(){
      const res = await apiContact();
      this.item = res.data;
      const {contact,item,item1} = res.data
      this.contact = {
        ...contact[0]
      }
      this.itemOne = {
        ...item
      }
      this.itemTwo = {
        ...item1
      }
    },
  },
};
</script>

<style lang="css">
    .modal-dialog{
      max-height: 80%;
      max-width: 90%;
      margin: auto;
      margin-top: 45px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }
    @media screen and (min-width: 768px){
      .modal-dialog {
        max-width: 70%;
        max-height: 500px;
        
      }  
    }
    @media screen and (min-width: 1200px){
      .modal-dialog {
        margin-top: 20vh; 
      }  
    }
</style>